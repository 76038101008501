import {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import LanguageSwitcher from 'components/Language';
import Link from 'next/link';
import cns from 'classnames';
import LinkWithArrow from 'components/Site/NewLanding/UI/LinkWithArrow';
import { useProductEvents } from 'hooks';
import isEmpty from 'lodash/isEmpty';
import HeaderProfile from './Profile';
import styles from './header.module.scss';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CloseIcon from '@material-ui/icons/Close';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import {
  OptimizelyExperiment,
  OptimizelyVariation,
  withOptimizely,
} from '@optimizely/react-sdk';

function Header({ userData, isLanding, optimizely }) {
  const [visibleModal, setVisibleModal] = useState(null);
  const { t: translateHeader } = useTranslation('header');
  const { t: translateLanding } = useTranslation('landing');
  const events = useProductEvents();
  const { profile } = userData;
  const tariffsDiscountIsActive = false;
  const tariffsSaleIsActive = false;
  const router = useRouter();

  /*useEffect(() => {
    const modals = document.querySelectorAll('.header-modal');

    const hideModalHandler = (e) => {
      const target = e.target;
      const modal_is_visible = visibleModal !== null;
      let its_modals = false;

      modals.forEach((modal) => {
        if (target === modal || modal.contains(target)) {
          its_modals = true;
        }
      })

      console.log(visibleModal)

      console.log(its_modals)
      console.log(modal_is_visible)

      if (!its_modals && modal_is_visible) {
        setVisibleModal(null);
        document.body.classList.remove('disable-scroll');
      }
    }

    document.addEventListener('click', hideModalHandler);

    return () => {
      document.removeEventListener('click', hideModalHandler);
    }

  }, [visibleModal])*/


  useEffect(() => {
    const ecsHandler = (evt) => {
      evt = evt || window.event;
      let isEscape = false;
      if ('key' in evt) {
        isEscape = (evt.key === 'Escape' || evt.key === 'Esc');
      } else {
        isEscape = (evt.keyCode === 27);
      }
      if (isEscape) {
        setVisibleModal(null);
      }
    };

    document.addEventListener('keydown', ecsHandler);

    return () => {
      document.removeEventListener('keydown', ecsHandler);
    };
  }, []);

  useEffect(() => {
    const links = document.querySelectorAll('header a');

    const linkClickHandler = () => {
      setVisibleModal(null);
      document.body.classList.remove('disable-scroll');
    };

    links.forEach((elem) => {
      elem.addEventListener('click', linkClickHandler);
    });

    return () => {
      links.forEach((elem) => {
        elem.removeEventListener('click', linkClickHandler);
      });
    };
  }, []);


  const onOpenNavModalClick = (modalName) => {
    document.body.classList.add('disable-scroll');
    setVisibleModal(modalName);
  };


  const onCloseNavModalClick = () => {
    document.body.classList.remove('disable-scroll');
    setVisibleModal(null);
  };
  const getPromoUrl = (anchor) => {
    if (router.query.promo) {
      return `/?promo=${router.query.promo}#${anchor}`;
    } else {
      return `/#${anchor}`;
    }
  };
  console.log(router);
  return (
    <header className={styles.root} id={'main'}>
      <div className={cns(styles.modal_overlay, { visible: visibleModal })} />
      <div className={styles.container}>
        <div className={styles.logo}>
          <Link href={getPromoUrl('')}>
            <a>
              <img src="/logo_orange_unfilled.svg" alt="logo" />
            </a>
          </Link>
        </div>

        <If condition={isLanding}>
          <nav className={styles.sections}>
            <Link href={getPromoUrl('forwhom')}>
              <a
                className={styles.section}
              >
                {translateLanding('sections.forwhom.headerTitle')}
              </a>
            </Link>
            <Link href={getPromoUrl('features')}>
              <a
                className={styles.section}
              >

                {translateHeader('features.title')}
              </a>
            </Link>
            <Link href={getPromoUrl('tarrifs')}>
              <a
                className={styles.section}
              >
                Тарифы
              </a>
            </Link>
            <Link href={getPromoUrl('reviews')}>
              <a
                className={styles.section}
              >
                Отзывы
              </a>
            </Link>
            <Link href="/about-company">
              <a
                className={styles.section}
              >
                О ZEN.CAR
              </a>
            </Link>

          </nav>


          <If condition={isEmpty(profile)}>
            <OptimizelyExperiment experiment="zen_car_workshop_landing_try_demo_test">
              <OptimizelyVariation variation="variation_try_free">
                <Link href="/access?tab=signup">
                  <a
                    className="z-button"
                    style={{ marginRight: '10px' }}
                    onClick={() => {
                      optimizely.track('click_main_action_button');
                      events.send('click_button__try_free_in_header__workshop');
                    }}
                  >
                    <span>{translateLanding('freeTrialBtn')}</span>
                  </a>
                </Link>
              </OptimizelyVariation>
              <OptimizelyVariation variation="variation_order_demo">
                <Link href="https://forms.yandex.ru/cloud/631064d428c2a452963f4a1c/">
                  <a
                    className="z-button"
                    style={{ marginRight: '10px' }}
                    onClick={() => {
                      optimizely.track('click_main_action_button');
                      events.send('click_button__demo_in_header__workshop');
                    }}
                  >
                    <span>{translateLanding('orderDemoBtn')}</span>
                  </a>
                </Link>
              </OptimizelyVariation>
              <OptimizelyVariation default>
                <Link href="/access?tab=signup">
                  <a
                    className="z-button"
                    style={{ marginRight: '10px' }}
                    onClick={() => {
                      optimizely.track('click_main_action_button');
                      events.send('click_button__try_free_in_header__workshop');
                    }}
                  >
                    <span>{translateLanding('freeTrialBtn')}</span>
                  </a>
                </Link>
              </OptimizelyVariation>
            </OptimizelyExperiment>
          </If>
          <LanguageSwitcher isLanding />

          <If condition={isEmpty(profile)}>
            <Link href="/access?tab=auth">
              <a
                className={styles.auth}
                onClick={() => {
                  events.send('click_button_enter_on_main_page_workshop');
                }}
              >
                <PersonOutlineIcon />
              </a>
            </Link>
          </If>

          <If condition={!isEmpty(profile)}>
            <HeaderProfile data={profile} />
          </If>

        </If>
      </div>

      <div className={cns('header-modal', styles.nav_modal, { visible: visibleModal === 'features' })}>
        <div className={styles.modal_container}>
          <div className={styles.nav_header_row}>
            <div className={styles.nav_title}>{translateHeader('features.title')}</div>
            <div
              className={styles.close_button}
              onClick={() => {
                onCloseNavModalClick(null);
              }}
            >
              <CloseIcon />
            </div>
          </div>

          <div className={styles.navigation}>
            <nav>
              <div className={styles.feature_block}>
                <Link href="/features?tab=repair">
                  <a>
                    <div className={styles.feature_title}>
                      <span>{translateHeader('features.workOrder')}</span>
                      <ArrowForwardIcon />
                    </div>

                    <div className={styles.feature_description}>
                      {translateHeader('features.workOrderDescription')}
                    </div>
                  </a>
                </Link>
              </div>
              <div className={styles.feature_block}>
                <Link href="/features?tab=booking">
                  <a>
                    <div className={styles.feature_title}>
                      <span>{translateHeader('features.booking')}</span>
                      <ArrowForwardIcon />
                    </div>
                    <div className={styles.feature_description}>
                      {translateHeader('features.bookingDescription')}
                    </div>
                  </a>
                </Link>
              </div>
              <div className={styles.feature_block}>
                <Link href="/features?tab=store">
                  <a>
                    <div className={styles.feature_title}>
                      <span>{translateHeader('features.stock')}</span>
                      <ArrowForwardIcon />
                    </div>
                    <div className={styles.feature_description}>
                      {translateHeader('features.stockDescription')}
                    </div>
                  </a>
                </Link>
              </div>
              <div className={styles.feature_block}>
                <Link href="/features?tab=analytics">
                  <a>
                    <div className={styles.feature_title}>
                      <span>{translateHeader('features.analysis')}</span>
                      <ArrowForwardIcon />
                    </div>
                    <div className={styles.feature_description}>
                      {translateHeader('features.analysisDescription')}
                    </div>
                  </a>
                </Link>
              </div>
            </nav>
          </div>
        </div>
      </div>

      <div className={cns('header-modal', styles.nav_modal, { visible: visibleModal === 'tariffs' })}>
        <div className={styles.modal_container}>
          <div className={styles.nav_header_row}>
            <div className={styles.nav_title}>{translateLanding('sections.tariffs.title')}</div>
            <div
              className={styles.close_button}
              onClick={() => {
                onCloseNavModalClick(null);
              }}
            >
              <CloseIcon />
            </div>
          </div>

          <div className={styles.tariffs_row}>
            <div className={styles.tariff}>
              <div className={styles.price_row}>
                <If condition={tariffsSaleIsActive}>
                  {/* <div className={styles.price_old}>{translateLanding('sections.tariffs.price_1')}</div>
                  <div className={styles.price}>{translateLanding('sections.tariffs.price_1_discount')}</div> */}
                </If>
                <If condition={!tariffsSaleIsActive}>
                  <div className={styles.price}>{translateLanding('sections.tariffs.price_1')}</div>
                </If>
                <div className={styles.price_currency}>{translateLanding('sections.tariffs.perMonth')}</div>
              </div>
              <div className={styles.payment_period}>
                {translateLanding('sections.tariffs.payment_1_month')}
              </div>
              <div className={styles.action}>
                <Link href="/access?tab=signup">
                  <a
                    className="z-button button-lg skeleton full-width"
                    onClick={() => {
                      events.send('click_button__choose_tariff_in_header__workshop',
                        { price: translateLanding('sections.tariffs.price_1') },
                      );
                    }}
                  >
                    {translateLanding('sections.tariffs.choose')}
                  </a>
                </Link>
              </div>
            </div>
            <div className={styles.tariff}>
              <div className={styles.price_row}>
                <If condition={tariffsSaleIsActive}>
                  {/* <div className={styles.price_old}>{translateLanding('sections.tariffs.price_2')}</div>
                  <div className={styles.price}>{translateLanding('sections.tariffs.price_2_discount')}</div> */}
                </If>
                <If condition={!tariffsSaleIsActive}>
                  <div className={styles.price}>{translateLanding('sections.tariffs.price_2')}</div>
                </If>
                <div className={styles.price_currency}>{translateLanding('sections.tariffs.perMonth')}</div>
              </div>
              <div className={styles.payment_period}>
                {translateLanding('sections.tariffs.payment_6_month')}
              </div>
              <div className={styles.action}>
                <Link href="/access?tab=signup">
                  <a
                    className="z-button button-lg skeleton full-width"
                    onClick={() => {
                      events.send('click_button__choose_tariff_in_header__workshop',
                        { price: translateLanding('sections.tariffs.price_2') },
                      );
                    }}
                  >
                    {translateLanding('sections.tariffs.choose')}
                  </a>
                </Link>
              </div>
            </div>
            <div className={cns(styles.tariff)}>
              <div className={styles.price_row}>
                <If condition={tariffsSaleIsActive}>
                  {/* <div className={styles.price_old}>{translateLanding('sections.tariffs.price_3')}</div>
                  <div className={styles.price}>{translateLanding('sections.tariffs.price_3_discount')}</div> */}
                </If>
                <If condition={!tariffsSaleIsActive}>
                  <div className={styles.price}>{translateLanding('sections.tariffs.price_3')}</div>
                </If>
                <div className={styles.price_currency}>{translateLanding('sections.tariffs.perMonth')}</div>
              </div>
              <div className={styles.payment_period}>
                {translateLanding('sections.tariffs.payment_12_month')}
              </div>
              <div className={styles.action}>
                <Link href="/access?tab=signup">
                  <a
                    className="z-button button-lg skeleton full-width"
                    onClick={() => {
                      events.send('click_button__choose_tariff_in_header__workshop',
                        { price: translateLanding('sections.tariffs.price_3') },
                      );
                    }}
                  >
                    {translateLanding('sections.tariffs.choose')}
                  </a>
                </Link>
              </div>
            </div>
          </div>

          <div className={styles.tariffs_bottom}>
            <div className={styles.tariffs_trial_button}>
              <Link href="/access?tab=signup">
                <a
                  className="z-button button-xl"
                  onClick={() => {
                    events.send('click_button__try_free_on_tariffs_header_section__workshop');
                  }}
                >
                  {translateLanding('freeTrialBtn')}
                </a>
              </Link>
              <div className={styles.tariffs_trial_button_note}>
                {translateLanding('freeTrialDescription2.text1')}{' '}
                <span>{translateLanding('freeTrialDescription2.text2')}</span>
                {' '}{translateLanding('freeTrialDescription2.text3')}{' '}
                <span>{translateLanding('freeTrialDescription2.text4')}</span><br />
                {' '}{translateLanding('freeTrialDescription2.text5')}
              </div>
            </div>

            <div className={styles.link_row}>
              <LinkWithArrow href="/prices" text={translateLanding('sections.tariffs.moreInformation')} />
            </div>
          </div>
        </div>
      </div>

      <div className={cns('header-modal', styles.nav_modal, { visible: visibleModal === 'company' })}>
        <div className={styles.modal_container}>
          <div className={styles.nav_header_row}>
            <div className={styles.nav_title}>{translateHeader('company.title')}</div>
            <div
              className={styles.close_button}
              onClick={() => {
                onCloseNavModalClick(null);
              }}
            >
              <CloseIcon />
            </div>
          </div>

          <div className={styles.navigation}>
            <nav>
              <div className={styles.nav_block}>
                <Link href="/about-company">
                  <a>
                    <span>{translateHeader('company.aboutUs')}</span>
                    <ArrowForwardIcon />
                  </a>
                </Link>
                <Link href="/about-company#mass-media">
                  <a>
                    <span>{translateHeader('company.publications')}</span>
                    <ArrowForwardIcon />
                  </a>
                </Link>
              </div>
              <div className={styles.nav_block}>
                <Link href="/reviews">
                  <a>
                    <span>{translateHeader('company.reviews')}</span>
                    <ArrowForwardIcon />
                  </a>
                </Link>
                <Link href="/articles">
                  <a>
                    <span>{translateHeader('company.articles')}</span>
                    <ArrowForwardIcon />
                  </a>
                </Link>
              </div>
              <div className={styles.nav_block}>
                <Link href="/faq">
                  <a>
                    <span>{translateHeader('company.faq')}</span>
                    <ArrowForwardIcon />
                  </a>
                </Link>
                <Link href="https://zencar.atlassian.net/wiki/spaces/ZW/overview">
                  <a target="_blank">
                    <span>{translateHeader('company.manual')}</span>
                    <ArrowForwardIcon />
                  </a>
                </Link>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
}

Header.propTypes = {
  userData: PropTypes.object,
};

export default withOptimizely(Header);