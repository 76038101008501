import PropTypes          from 'prop-types';
import { useTranslation } from 'next-i18next';
import { useRouter }      from 'next/router';
import Link               from 'next/link';
import UserStory          from 'components/User/Story';
import {
  getUserName,
  getDomainForCookie
}                         from 'helpers/helpers';
import { eraseCookie }    from 'helpers/cookies';
import {
  TOKEN_COOKIE_NAME
}                         from 'helpers/constants';
import { Dropdown }       from 'semantic-ui-react'
import isEmpty            from 'lodash/isEmpty';
import sortBy             from 'lodash/sortBy';
import styles             from './header-profile.module.scss';

import LogoutIcon         from '@material-ui/icons/ExitToAppOutlined';
import PersonOutlineIcon  from '@material-ui/icons/PersonOutline';
import LockOpenIcon       from '@material-ui/icons/LockOpen';

function HeaderProfile ({ data, reset }) {
  const { t } = useTranslation('header');
  const router = useRouter();

  const logout = (e) => {
    e.preventDefault();
    reset();
    eraseCookie(TOKEN_COOKIE_NAME, getDomainForCookie());
    eraseCookie(TOKEN_COOKIE_NAME);
    router.replace(`/`);
  };

  return (
    <div className={styles.root}>
      <PersonOutlineIcon/>
      <Dropdown text={getUserName(data.name)}>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => {router.push('/repairs?tab=all')}}
          >
            <LockOpenIcon/>
            <span>{t('profileMenu.enter')}</span>
          </Dropdown.Item>
          <Dropdown.Item
            onClick={logout}
          >
            <LogoutIcon/>
            <span>{t('profileMenu.logout')}</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      {/*<Link href="/repairs?tab=all">
        <a>
          <UserStory
            image={!isEmpty(data.photos) ? sortBy(data.photos, 'id').reverse()[0].file.url : undefined}
            size="small"
            showName={false}
            hasStory={false}
          />
          
          <div className={styles.content}>
            <div className={styles.name}>
              {getUserName(data.name)}
            </div>
            <div className={styles.phone}>
              {t('site.menu.toApp')}
            </div>
          </div>
        </a>
      </Link>

      <div
        className={styles.logout}
        onClick={logout}
      >
        <div className={styles.logout_icon}>
          <LogoutIcon/>
        </div>
        <div className={styles.logout_text}>
          {t('common.actions.logout')}
        </div>
      </div>*/}
    </div>
  )
};

HeaderProfile.propTypes = {
  data: PropTypes.object.isRequired,
  reset: PropTypes.func.isRequired
};

export default HeaderProfile;