import { memo }        from 'react';
import { useSelector } from 'react-redux';
import Header          from './Component';

function ConnectedHeader (props) {
  const userData = useSelector(state => state.auth.profile);

  return (
    <Header
      userData={userData}
      {...props}
    />
  )
};

export default memo(ConnectedHeader);