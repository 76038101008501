import { useSelector } from 'react-redux';
import SiteMenu        from './Component';
import isEmpty         from 'lodash/isEmpty';

function ConnectedSiteMenu (props) {
  const userData = useSelector(state => state.auth.profile);

  return (
    <SiteMenu
      isAuthed={!isEmpty(userData)}
      {...props}
    />
  )
};

export default ConnectedSiteMenu;