import { useDispatch } from 'react-redux';
import HeaderProfile     from './Component';
import { resetUser }   from 'reducers/auth';
import API             from 'api';

function ConnectedHeaderProfile (props) {
  const dispatch = useDispatch();

  const reset = () => {
    dispatch(API.queries.garage.reset());
    dispatch(resetUser());
  };

  return (
    <HeaderProfile
      reset={reset}
      {...props}
    />
  )
}

export default ConnectedHeaderProfile;