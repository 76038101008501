import PropTypes from 'prop-types';
import styles    from './link-with-arrow.module.scss';

import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Link             from "next/link";

const LinkWithArrow = ({ href, text }) => {
  return (
    <Link href={href}>
      <a className={styles.root}>
        <span>{text}</span>
        <ArrowForwardIcon/>
      </a>
    </Link>
  )
};

LinkWithArrow.propTypes = {
  text: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired
};

export default LinkWithArrow;