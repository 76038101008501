import { useState } from 'react';
import PropTypes    from 'prop-types';
import cns    from 'classnames';
import styles from './collapse-mobile-menu-item.module.scss';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandLessIcon   from '@material-ui/icons/ExpandLess';

const CollapseMobileMenuItem = ({ title, children }) => {
  const [isOpen, setOpenState] = useState(false);

  return (
    <div className={styles.root}>
      <div
        className={styles.title_row}
        onClick={() => {console.log(isOpen); setOpenState(!isOpen)}}
      >
        <div className={styles.title}>
          {title}
        </div>
        <If condition={!isOpen}>
          <ChevronRightIcon/>
        </If>
        <If condition={isOpen}>
          <ExpandLessIcon/>
        </If>
      </div>
      <div className={cns(styles.content, {show: isOpen})}>
        {children}
      </div>
    </div>
  )
};

CollapseMobileMenuItem.propTypes = {
  title: PropTypes.string.isRequired
};

export default CollapseMobileMenuItem;