import Link from 'next/link';
import UICircleButton from 'components/UI/CircleButton';
import { useTranslation } from 'next-i18next';
import styles from './footer.module.scss';
import PinDropIcon from '@material-ui/icons/PinDrop';
import TelegramIcon from '@material-ui/icons/Telegram';
import { YouTube } from '@material-ui/icons';

function Footer() {
  const { t } = useTranslation('landing');

  return (
    <footer className={styles.root}>
      <div className={styles.container}>
        <div className={styles.logo}>
          <Link href="/">
            <a>
              <img src="/logo_orange_unfilled.svg" alt="logo" />
            </a>
          </Link>
        </div>
        <div className={styles.menu}>
          <div className={styles.block}>
            <nav>
              <Link href="/about-company">
                <a>
                  <span>{t('footer.links.aboutCompany')}</span>
                </a>
              </Link>
              <Link href="/about-company#mass-media">
                <a>
                  <span>{t('footer.links.publications')}</span>
                </a>
              </Link>
              <Link href="/articles">
                <a>
                  <span>{t('footer.links.articles')}</span>
                </a>
              </Link>
              <Link href="/#tarrifs">
                <a>
                  <span>{t('footer.links.tariffs')}</span>
                </a>
              </Link>
            </nav>
          </div>

          <div className={styles.block}>
            <nav>
              <Link href="/features?tab=repair">
                <a>
                  <span>{t('footer.links.workOrders')}</span>
                </a>
              </Link>
              <Link href="/features?tab=booking">
                <a>
                  <span>{t('footer.links.booking')}</span>
                </a>
              </Link>
              <Link href="/features?tab=store">
                <a>
                  <span>{t('footer.links.stock')}</span>
                </a>
              </Link>
              <Link href="/features?tab=analytics">
                <a>
                  <span>{t('footer.links.analytics')}</span>
                </a>
              </Link>
            </nav>
          </div>

          <div className={styles.block}>
            <nav>
              <Link href="/offer.pdf">
                <a target="_blank">
                  <span>{t('footer.links.offer')}</span>
                </a>
              </Link>
              <Link href="/reviews">
                <a>
                  <span>{t('footer.links.reviews')}</span>
                </a>
              </Link>
              <Link href="/faq">
                <a>
                  <span>{t('footer.links.faq')}</span>
                </a>
              </Link>
            </nav>
          </div>
        </div>

        <div className={styles.bottom}>
          <div className={styles.contacts_container}>
            {/*<a href="https://goo.gl/maps/mtqqqSHp87hGpXZ9A" target="_blank">*/}
            <div style={{ color: 'white', marginBottom: '8px' }}>ООО Зенкар ИНН 1615012690 ОГРН 1161690093894 основано в 2016 г.</div>
            <div style={{ color: 'white' }}>{t('footer.address')}</div>
            <a href="https://goo.gl/maps/mtqqqSHp87hGpXZ9A" target="_blank">
              <div className={styles.address}><PinDropIcon /> {t('footer.checkMap')}</div>
            </a>
          </div>

          <div className={styles.social}>
            <div className={styles.social_links}>
              <UICircleButton
                href="https://t.me/workshop_zencar"
                target="_blank"
                icon={<TelegramIcon />}
              />
              <UICircleButton
                href="https://www.youtube.com/channel/UCeziMUj3Kd5lkAPZ8XJMrqQ"
                target="_blank"
                icon={<YouTube />}
              />
              <UICircleButton
                href="https://vk.com/zencar"
                target="_blank"
                icon={<img src="/new_promo_page/images/vk.svg" alt="vk" />}
              />
            </div>
            <div className={styles.email}>
              <a href={'mailto:support@zencar.tech'}>support@zencar.tech</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer;