import { useTranslation } from 'next-i18next';
import SideMenuLeaf       from 'components/Navigation/SideMenu/Leaf';
import SideMenuHeader     from 'components/Navigation/SideMenu/Header';
import UserProfile        from 'components/Navigation/SideMenu/Profile';
import styles             from './site-menu.module.scss';
import common             from 'components/Navigation/SideMenu/side-menu.module.scss';

import HomeIcon       from '@material-ui/icons/HomeOutlined';
import AppIcon        from '@material-ui/icons/DesktopWindowsOutlined';
import SignInIcon     from '@material-ui/icons/LockOpenOutlined';
import SignUpIcon     from '@material-ui/icons/AssignmentIndOutlined';
import ArticlesIcon   from '@material-ui/icons/ChromeReaderModeOutlined';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';

const SiteMenu = ({isAuthed}) => {
  const {t} = useTranslation();

  const hideMenu = () => {
    document.body.classList.remove('menu-opened');
  };

  return (
    <>
      <div className={styles.overlay} onClick={hideMenu}/>

      <div className={styles.root}>
        <SideMenuHeader showLanguageSwitcher={false}/>        

        <div className={common.scroll}>
          <div className={common.scroll_content}>          
            <div className={common.nav}>
              <div className={common.permanent}>
                <SideMenuLeaf
                  icon={<HomeIcon/>}
                  text={t('site.pages.main')}
                  route="/"
                />

                <Choose>
                  <When condition={isAuthed}>
                    <SideMenuLeaf
                      icon={<AppIcon/>}
                      text={t('site.menu.toApp')}
                      route="/repairs?tab=all"
                    />
                  </When>
                  <Otherwise>
                    <SideMenuLeaf
                      icon={<SignInIcon/>}
                      text={t('site.pages.auth')}
                      route="/access?tab=auth"
                    />
                    <SideMenuLeaf
                      icon={<SignUpIcon/>}
                      text={t('site.pages.signup')}
                      route="/access?tab=signup"
                    />
                  </Otherwise>
                </Choose>
                
                <SideMenuLeaf
                  icon={<ArticlesIcon/>}
                  text={t('common.articles.many')}
                  route="/articles"
                />
              </div>
            </div>

            <div className={styles.social}>
              <a href="https://vk.com/zencar" target="_blank">
                <img src="/images/svg/vk.svg" alt=""/>
              </a>
              <a href="https://www.youtube.com/channel/UCeziMUj3Kd5lkAPZ8XJMrqQ" target="_blank">
                <img src="/images/svg/youtube.svg" alt=""/>
              </a>
            </div>
            <div className={styles.support}>
              <a href="tel:88007770077" target="_blank">
                <HeadsetMicIcon/> 8 800 777-00-77
              </a>
            </div>

            <div className={styles.footer}>
              &copy; 2016 - {new Date().getFullYear()} ZEN.CAR
            </div>

          </div>
        </div>

        <UserProfile/>
      </div>
    </>
  )
};

export default SiteMenu;