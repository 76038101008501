import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import SitePage               from './Component';
import { 
  applyUser, applyUserProfile 
}                             from 'reducers/auth';
import API                    from 'api';

const mapStateToProps = (state) => ({
  auth: state.auth
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({applyUser, applyUserProfile}, dispatch),
  getUser: (user) => {
    return dispatch(API.queries.profile());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(SitePage);