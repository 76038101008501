import {
  memo,
  useCallback,
  useEffect,
}                     from 'react';
import PropTypes      from 'prop-types';
import { useRouter }  from 'next/router';
import { useUser }    from 'hooks';
import SiteMenu       from 'components/Site/Menu';
import PageHead       from './Head';
import Header         from './Header';
import MobileTopPanel from './MobileTopPanel';
import Footer         from './Footer';
import cns            from 'classnames';
//import Snowflakes         from 'components/Site/Snowflakes';
//import NewYearBottomImage from 'components/Site/NewYearBottomImage';

import isEmpty from 'lodash/isEmpty';
import styles  from './site-page.module.scss';

const SitePage = (props) => {
  const {
    title, auth, applyUser, applyUserProfile, getUser,
    contentClass, children, keywords, ogURL, ogImage,
    isLanding, hideHeaderButton,
  } = props;
  const user = useUser();
  const router = useRouter();
  const {pathname} = router;
  const contentClassNames = [styles.content];

  if (contentClass) {
    contentClassNames.push(styles[contentClass]);
  }

  const checkAuth = useCallback(() => {
    if (user !== false) {
      applyUser(user);

      if (isEmpty(auth.profile)) {
        getUser(user)
          .then(res => applyUserProfile(res.data[user.type]))
          .catch(err => console.log(err))
      }

      if (pathname === '/auth' || pathname === '/signup' || pathname === '/access') {
        router.replace('/workbench');
      }
    }
  });

  useEffect(() => {
    checkAuth();
    document.body.classList.remove('disable-scroll');
  }, []);

  return (
    <>
      <PageHead
        title={title}
        keywords={keywords}
        ogURL={ogURL}
        ogImage={ogImage}
      />
      <div className={styles.root}>
        <Header
          isLanding={isLanding}
          userData={auth}
        />
        <MobileTopPanel
          hideHeaderButton={hideHeaderButton}
        />
        <SiteMenu/>

        <div className={cns(styles.content, {
          [styles.mobile_no_margin]: isLanding,
        })}>
          <div className={styles.view}>
            {children}
          </div>

          <Footer/>
        </div>

        {/*<NewYearBottomImage/>*/}
        {/*<Snowflakes/>*/}
      </div>
    </>
  );
};

SitePage.propTypes = {
  contentClass: PropTypes.string,
  title: PropTypes.string.isRequired,
  auth: PropTypes.object.isRequired,
  applyUser: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
};

export default memo(SitePage);