import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import Head from 'next/head';
import isEmpty from 'lodash/isEmpty';
import { PROJECT_NAME } from 'helpers/constants';

function PageHead({ title, keywords, ogURL, ogImage }) {
  const { t } = useTranslation('common');

  return (
    <Head>
      <title>{title} | {PROJECT_NAME}</title>
      <meta name="description" content={t('og.description')} />
      <meta name="keywords" content={!isEmpty(keywords) ? keywords : t('og.keywords')} />
      <meta name="google-site-verification" content="2bTyM4wHYrxN4Ghiq9SN12sdxt4bTr0Ml3uOA5D1yfE" />
      <meta property="og:url" content={ogURL || "http://zencar.tech"} />
      <meta property="og:title" content={`${title} | ${PROJECT_NAME}`} />
      <meta property="og:description" content={t('og.description')} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={ogImage || "/new_promo_page/images/priem-avto.png"} />
    </Head>
  )
};

PageHead.propTypes = {
  title: PropTypes.string.isRequired
};

export default PageHead;