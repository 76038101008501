import { useState }           from 'react';
import LanguageSwitcher       from 'components/Language';
import Link                   from 'next/link';
import cns                    from 'classnames';
import CollapseMobileMenuItem from './components/CollapseMobileMenuItem';
import styles                 from './mobile-top-panel.module.scss';

import MenuIcon             from '@material-ui/icons/Menu';
import CloseIcon            from '@material-ui/icons/Close';
import ChevronRightIcon     from '@material-ui/icons/ChevronRight';
import { useTranslation }   from 'next-i18next';
import PersonOutlinedIcon   from '@material-ui/icons/PersonOutlined';
import {
  OptimizelyExperiment,
  OptimizelyVariation,
  withOptimizely,
}                           from '@optimizely/react-sdk';
import { useProductEvents } from 'hooks';


const MobileTopPanel = ({hideHeaderButton, optimizely}) => {
  const {t: t} = useTranslation('header');
  const {t: translateLanding} = useTranslation('landing');
  const events = useProductEvents();
  const [menuToggleState, setMenuToggleState] = useState(false);

  const onMenuClick = () => {
    setMenuToggleState(!menuToggleState);
    // document.body.classList.toggle('disable-scroll')
  };

  return (
    <div className={cns(styles.root, {menu_opened: menuToggleState})}>
      <div className={styles.top_panel_container}>
        <div className={styles.menu_button} onClick={onMenuClick}>
          <If condition={!menuToggleState}>
            <MenuIcon/>
          </If>
          <If condition={menuToggleState}>
            <CloseIcon/>
          </If>
        </div>


        <If condition={!menuToggleState}>
          <div className={styles.logo}>
            <Link href="/">
              <a>
                <img src="/logo_orange_unfilled.svg" alt="logo"/>
              </a>
            </Link>
          </div>
          <If condition={!hideHeaderButton}>
            <div className={styles.action}>
              <OptimizelyExperiment experiment="zen_car_workshop_landing_try_demo_test">
                <OptimizelyVariation variation="variation_try_free">
                  <Link href='/access?tab=signup'>
                    <a className="z-button"
                       onClick={() => {
                         optimizely.track('click_main_action_button');
                         events.send('click_button__try_free_in_header__workshop');
                       }}
                    >{t('try')}</a>
                  </Link>
                </OptimizelyVariation>
                <OptimizelyVariation variation="variation_order_demo">
                  <Link href='https://forms.yandex.ru/cloud/631064d428c2a452963f4a1c/'>
                    <a className="z-button"
                       onClick={() => {
                         optimizely.track('click_main_action_button');
                         events.send('click_button__demo_in_header__workshop');
                       }}
                    >{translateLanding('orderDemoBtn')}</a>
                  </Link>
                </OptimizelyVariation>
                <OptimizelyVariation default>
                  <Link href='/access?tab=signup'>
                    <a className="z-button"
                       onClick={() => {
                         optimizely.track('click_main_action_button');
                         events.send('click_button__try_free_in_header__workshop');
                       }}
                    >{t('try')}</a>
                  </Link>
                </OptimizelyVariation>
              </OptimizelyExperiment>

              <div>
                <Link href="/access?tab=auth">
                  <a className={styles.auth}>
                    <PersonOutlinedIcon/>
                  </a>
                </Link>
              </div>

            </div>
          </If>
        </If>

        <If condition={menuToggleState}>
          <div className={styles.lang}>
            <LanguageSwitcher
              toggleByIcon={true}
              isLanding
            />
          </div>
        </If>

        {/*<If condition={menuToggleState}>
          <div className={styles.right_block}>
            <div className={styles.profile}>
              <PersonOutlinedIcon/>
            </div>
            <div className={styles.lang}>
              <LanguageSwitcher toggleByIcon={true}/>
            </div>
          </div>
        </If>*/}
      </div>

      <div className={styles.menu}>

        <CollapseMobileMenuItem title={t('features.title')}>
          <nav>
            <Link href="/#features">
              <a>
                {t('features.workOrder')}
              </a>
            </Link>
            <Link href="/#features">
              <a>
                {t('features.booking')}
              </a>
            </Link>
            <Link href="/#features">
              <a>
                {t('features.stock')}
              </a>
            </Link>
            <Link href="/#features">
              <a>
                {t('features.analysis')}
              </a>
            </Link>
          </nav>
        </CollapseMobileMenuItem>

        <div className={styles.mobile_main_link}>
          <Link href="/#tarrifs">
            <a>
              <span>{t('tariffs.title')}</span>
              <ChevronRightIcon/>
            </a>
          </Link>
        </div>

        <CollapseMobileMenuItem title='О ZEN.CAR'>
          <nav>
            <Link href="/about-company">
              <a>
                {t('company.title')}
              </a>
            </Link>
            <Link href="/about-company#mass-media">
              <a>
                {t('company.publications')}
              </a>
            </Link>
            <Link href="/articles">
              <a>
                {t('company.articles')}
              </a>
            </Link>
            <Link href="/#reviews">
              <a>
                {t('company.reviews')}
              </a>
            </Link>
            <Link href="/faq">
              <a>
                {t('company.faq')}
              </a>
            </Link>
          </nav>
        </CollapseMobileMenuItem>

      </div>
    </div>
  )
};

export default withOptimizely(MobileTopPanel);